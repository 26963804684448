/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


:root {
	--ion-color-otro: #593489;
	--ion-color-otro-rgb: 89,52,137;
	--ion-color-otro-contrast: #ffffff;
	--ion-color-otro-contrast-rgb: 255,255,255;
	--ion-color-otro-shade: #4e2e79;
	--ion-color-otro-tint: #6a4895;
  }

  .ion-color-otro {
    --ion-color-base: var(--ion-color-otro);
    --ion-color-base-rgb: var(--ion-color-otro-rgb);
    --ion-color-contrast: var(--ion-color-otro-contrast);
    --ion-color-contrast-rgb: var(--ion-color-otro-contrast-rgb);
    --ion-color-shade: var(--ion-color-otro-shade);
    --ion-color-tint: var(--ion-color-otro-tint);
  }

  :root {
    --ion-color-indicador: #7a33ff;
    --ion-color-indicador-rgb: 122,51,255;
    --ion-color-indicador-contrast: #ffffff;
    --ion-color-indicador-contrast-rgb: 255,255,255;
    --ion-color-indicador-shade: #6b2de0;
    --ion-color-indicador-tint: #8747ff;
  }

  .ion-color-indicador {
    --ion-color-base: var(--ion-color-indicador);
    --ion-color-base-rgb: var(--ion-color-indicador-rgb);
    --ion-color-contrast: var(--ion-color-indicador-contrast);
    --ion-color-contrast-rgb: var(--ion-color-indicador-contrast-rgb);
    --ion-color-shade: var(--ion-color-indicador-shade);
    --ion-color-tint: var(--ion-color-indicador-tint);
  }

  :root {
    --ion-color-verde: #2dd36f;
    --ion-color-verde-rgb: 45,211,111;
    --ion-color-verde-contrast: #000000;
    --ion-color-verde-contrast-rgb: 0,0,0;
    --ion-color-verde-shade: #28ba62;
    --ion-color-verde-tint: #42d77d;
  }

  .ion-color-verde {
    --ion-color-base: var(--ion-color-verde);
    --ion-color-base-rgb: var(--ion-color-verde-rgb);
    --ion-color-contrast: var(--ion-color-verde-contrast);
    --ion-color-contrast-rgb: var(--ion-color-verde-contrast-rgb);
    --ion-color-shade: var(--ion-color-verde-shade);
    --ion-color-tint: var(--ion-color-verde-tint);
  }

  :root {
    --ion-color-liwa: #032843;
    --ion-color-liwa-rgb: 3,40,67;
    --ion-color-liwa-contrast: #ffffff;
    --ion-color-liwa-contrast-rgb: 255,255,255;
    --ion-color-liwa-shade: #03233b;
    --ion-color-liwa-tint: #1c3e56;
  }

  .ion-color-liwa {
    --ion-color-base: var(--ion-color-liwa);
    --ion-color-base-rgb: var(--ion-color-liwa-rgb);
    --ion-color-contrast: var(--ion-color-liwa-contrast);
    --ion-color-contrast-rgb: var(--ion-color-liwa-contrast-rgb);
    --ion-color-shade: var(--ion-color-liwa-shade);
    --ion-color-tint: var(--ion-color-liwa-tint);
  }
  #ForRadioOptions
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-radio {
   height: auto;
   contain: content;
}
#ForCheckbox
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-checkbox {
   height: auto;
   contain: content;
}
